import { authHeader, handleResponse } from '@/api/handler/main';

function GET_COUNT_EMPLOYEE_DASHBOARD(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.VUE_APP_APIURL}/dashboard/count-employee-campaign?${query}`, requestOptions).then(handleResponse);
}

function GET_LIST_CAMPAIGN_FOR_DASHBOARD(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.VUE_APP_APIURL}/dashboard/campaign/list-campagin?${query}`, requestOptions).then(handleResponse);
}

export const dashboardService = {
    GET_COUNT_EMPLOYEE_DASHBOARD,
    GET_LIST_CAMPAIGN_FOR_DASHBOARD
};
