export const routerPartner = [
    {
        path: '/partners',
        name: 'Partners',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['partner.view'],
            typeRouterForm: false,
            titleJP: '外部パートナー一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/partner')
    },
    {
        path: '/partner/form',
        name: 'PartnerForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['partner.register', 'partner.edit'],
            typeRouterForm: true,
            titleJPAdd: '外部パートナー新規登録',
            titleJPEdit: '外部パートナー新規編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店新規登録',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    href: '/partners'
                },
                {
                    text: '外部パートナー新規編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/partner/form/main.vue')
    },
    {
        path: '/partner/view',
        name: 'PartnerView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['partner.view'],
            typeRouterForm: false,
            titleJP: '外部パートナー詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    href: '/partners'
                },
                {
                    text: '外部パートナー詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/partner/view/main.vue')
    },
    {
        path: '/partner/form/support',
        name: 'PartnerSupport',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['partner.register', 'partner.edit'],
            typeRouterForm: true,
            titleJPAdd: '外部パートナー担当者登録',
            titleJPEdit: '外部パートナー新規編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    href: '/partners'
                },
                {
                    text: '外部パートナー担当者登録',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    href: '/partners'
                },
                {
                    text: '外部パートナー新規編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/partner/form/support.vue')
    },
    {
        path: '/partner/support/view',
        name: 'PartnerSupportView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['partner.register', 'partner.edit'],
            typeRouterForm: false,
            titleJP: '外部パートナー担当者詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    href: '/partners'
                },
                {
                    text: '外部パートナー担当者詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/partner/view/support.vue')
    }
];
