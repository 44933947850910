export const routerRoles = [
    {
        path: '/roles',
        name: 'Roles',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: '権限管理一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '権限管理一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/roles')
    }
];
