import { uploadImgService } from '@/api/services/upload-image.service';

export const state = () => ({
    infoImage: null
});

export const getters = {
    infoImage: (state) => state.infoImage
};

export const actions = {
    acUploadImage({ dispatch, commit }, data) {
        return uploadImgService.uploadImage(data).then(
            (data) => {
                commit('handleUploadImage', data.data);
                return data;
            },
            (error) => {
                commit('errorUpload', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    handleUploadImage(state, data) {
        state.infoImage = data;
    },

    errorUpload(state) {
        state.status = {};
        state.infoImage = null;
    }
};
