export const routerMaster = [
    {
        path: '/master/other',
        name: 'Master',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'その他管理一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'その他管理一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/other/index')
    },
    {
        path: '/master/other/form',
        name: 'MasterForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['master.register'],
            typeRouterForm: true,
            titleJPAdd: 'マスタ新規登録・編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'マスタ一覧',
                    href: '/master/other'
                },
                {
                    text: 'マスタ新規登録・編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/other/form.vue')
    },
    {
        path: '/master/holidays',
        name: 'Holiday',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: '休日マスタ',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '休日マスタ',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/holiday/main.vue')
    },
    {
        path: '/master/holiday-year',
        name: 'Holidayyear',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: '年末年始休みマスタ',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '年末年始休みマスタ',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/holiday-year/main.vue')
    },
    {
        path: '/master/template',
        name: 'Template',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: '当選配信文言テンプレート作成',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '当選配信文言テンプレート作成',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/template/main.vue')
    },
    {
        path: '/master/manual',
        name: 'Manual',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'マニュアル用マスタ',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'マニュアル用マスタ',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/manual/main.vue')
    },
    {
        path: '/master/template/preview',
        name: 'TemplatePreview',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: '削除してもよろしいですか。',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '削除してもよろしいですか。',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/documents/preview.vue')
    },
    {
        path: '/master/rule',
        name: 'MasterRule',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: '規約詳細一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '規約詳細一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/rule/main.vue')
    },
    {
        path: '/master/condition-rule',
        name: 'MasterRule1',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: ['master.register'],
            typeRouterForm: false,
            titleJP: '条件設定',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '条件設定',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/condition-rule/main.vue')
    },
    {
        path: '/master/condition-rule-edit',
        name: 'MasterRule1Edit',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: ['master.register'],
            typeRouterForm: true,
            titleJPEdit: '規約用条件設定',
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '規約用条件設定',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/condition-rule/form.vue')
    },
    {
        path: '/master/schedule/list',
        name: 'MasterScheduleList',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: ['master.register'],
            typeRouterForm: false,
            titleJP: 'スケジュール用設定',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'スケジュール用設定',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/schedule/list/main.vue')
    },
    {
        path: '/master/schedule/add',
        name: 'MasterScheduleEdit',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: ['master.register'],
            typeRouterForm: true,
            titleJPAdd: 'スケジュール用設定',
            titleJPEdit: 'スケジュール用設定',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'スケジュール用設定',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'スケジュール用設定',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/masters/schedule/add/main.vue')
    }
];
