export const routerLandingPage = [
    {
        path: '/landing-page/list',
        name: 'LandingPageList',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'LP一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'LP一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/landing-page')
    },
    {
        path: '/landing-page/create',
        name: 'LandingPageCreate',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: true,
            titleJPAdd: 'LP新規作成',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'LP新規作成',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/landing-page/create/main.vue')
    },
    {
        path: '/landing-page/edit',
        name: 'LandingPageEdit',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: true,
            titleJPEdit: 'LP編集',
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'LP一覧',
                    href: '/landing-page/list'
                },
                {
                    text: 'LP編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/landing-page/edit/main.vue')
    },
    {
        path: '/landing-page/view',
        name: 'LandingPageView',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'ViewLandingPage',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'LP一覧',
                    href: '/landing-page/list'
                },
                {
                    text: 'ViewLandingPage',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/landing-page/view/main.vue')
    }
];
