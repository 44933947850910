import { authHeader, handleResponse } from '@/api/handler/main';

export const userService = {
    login,
    logout,
    register,
    profile,
    getAll,
    forgot,
    reset
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(process.env.VUE_APP_APIURL + `/auth/login`, requestOptions)
        .then(handleResponseLogin)
        .then((user) => {
            const now = new Date();
            const expireTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59); // 23:59:59 hôm nay
            if (user && user.data && user.data.access_token) {
                localStorage.setItem('user', JSON.stringify(user.data.user));
                localStorage.setItem('expireTime', expireTime.getTime()); // Lưu timestamp
            }
            return user;
        });
}

function forgot(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch(process.env.VUE_APP_APIURL + `/auth/forgot-password`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data;
        });
}
function reset(password, password_confirm, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, password_confirm })
    };

    return fetch(process.env.VUE_APP_APIURL + `/auth/reset-password?token=${token}`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data;
        });
}

function logout() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    fetch(process.env.VUE_APP_APIURL + `/auth/logout`, requestOptions)
        .then(handleResponse)
        .then(() => {});
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function profile() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/profile`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            if (user && user.data) {
                localStorage.setItem('user', JSON.stringify(user.data));
            }
            return user.data;
        });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponseLogin(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401 || response.status === 422) {
                return data;
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
