export const routerFaq = [
    {
        path: '/faqs',
        name: 'FAQs',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['faq.view'],
            typeRouterForm: false,
            titleJP: 'FAQ管理一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'FAQ管理一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/faqs')
    },
    {
        path: '/faq/form',
        name: 'FAQsForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['faq.register', 'faq.edit'],
            typeRouterForm: true,
            titleJPAdd: 'FAQ新規登録',
            titleJPEdit: 'FAQ編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'FAQ新規登録',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'FAQマスタ一ー覧',
                    href: '/faqs'
                },
                {
                    text: 'FAQ編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/faqs/form')
    },
    {
        path: '/faq/view',
        name: 'FAQFormView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['faq.view'],
            typeRouterForm: false,
            titleJP: 'FAQ詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'FAQマスタ一ー覧',
                    href: '/faqs'
                },
                {
                    text: 'FAQ詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/faqs/view')
    }
];
