import { managerFormService } from '@/helpers/fakebackend/manager-form.service';

export const state = () => ({
    allInforCampaignLP: null,
    listDataManagerForm: []
});

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    },
    listDataManagerForm: (state) => state.listDataManagerForm
};

export const actions = {
    registerManagerForm({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return managerFormService.registerManagerForm(data).then(
            (data) => {
                commit('registerSuccess', data);
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateManagerForm({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return managerFormService.updateManagerForm(data).then(
            (data) => {
                commit('registerSuccess', data);
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getListManagerForm({ dispatch, commit }, query) {
        commit('listRequest', []);
        return managerFormService.getListManagerForm(query).then(
            (data) => {
                commit('listManagerFormSuccess', data.data);
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDetailManagerForm({ dispatch, commit }, data) {
        commit('listRequest', []);
        return managerFormService.getDetailManagerForm(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteManagerForm({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return managerFormService.deleteManagerForm(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    },

    listManagerFormSuccess(state, data) {
        state.listDataManagerForm = data;
    }
};
