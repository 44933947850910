import i18n from '@/i18n';

export const routerSettingPhone = [
    {
        path: '/setting-phone',
        name: 'SettingPhone',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: i18n.t('settingPhone.namePage'),
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: i18n.t('settingPhone.namePage'),
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/setting-phone/index.vue')
    }
];
