export const routerPrize = [
    {
        path: '/prize/list',
        name: 'Prize',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['prize.view'],
            typeRouterForm: false,
            titleJP: '商品一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '商品一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/prize')
    },
    {
        path: '/prize/form',
        name: 'PrizeForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['prize.register', 'prize.edit'],
            typeRouterForm: true,
            titleJPAdd: '賞品新規登録・編集',
            titleJPEdit: '賞品編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '賞品新規登録・編集',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '商品一覧',
                    href: '/prize/list'
                },
                {
                    text: '賞品編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/prize/form/main.vue')
    },
    {
        path: '/prize/form/view',
        name: 'PrizeFormView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['prize.view'],
            typeRouterForm: false,
            titleJP: '賞品詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '商品一覧',
                    href: '/prize/list'
                },
                {
                    text: '賞品詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/prize/view/main.vue')
    },
    {
        path: '/prize-return/list',
        name: 'PrizeReturn',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['return_prize.view'],
            typeRouterForm: false,
            titleJP: '賞品戻り管理一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '賞品戻り管理一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/prize/return/index.vue')
    },

    {
        path: '/prize-return/form',
        name: 'PrizeReturnForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['return_prize.register', 'return_prize.edit'],
            typeRouterForm: true,
            titleJPAdd: '賞品戻り新規登録',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '賞品戻り新規登録',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/prize/return/form')
    }
];
