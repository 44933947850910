import { landingPageService } from '@/helpers/fakebackend/landing-page.service';

export const state = () => ({
    allInforCampaignLP: null
});

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    },
    allInforCampaignLP: (state) => state.allInforCampaignLP
};

export const actions = {
    registerLandingPage({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return landingPageService.registerLandingPage(data).then(
            (data) => {
                commit('registerSuccess', data);
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateLandingPage({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return landingPageService.updateLandingPage(data).then(
            (data) => {
                commit('registerSuccess', data);
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getListLandingPage({ dispatch, commit }, query) {
        commit('listRequest', []);
        return landingPageService.getListLandingPage(query).then(
            (data) => {
                commit('listSuccess', data.data);
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDetailLandingPage({ dispatch, commit }, data) {
        commit('listRequest', []);
        return landingPageService.getDetailLandingPage(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDetailCampaignForLandingPage({ dispatch, commit }, data) {
        return landingPageService.getDetailCampaignForLandingPage(data).then(
            (data) => {
                commit('detailCampaignLPSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteLandingPage({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return landingPageService.deleteLandingPage(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    },

    detailCampaignLPSuccess(state, data) {
        state.allInforCampaignLP = data;
    }
};
