import { handleResponse } from '@/api/handler/main';

function uploadImage(data) {
    const requestOptions = {
        headers: {
            Authorization: 'Bearer ' + window.$cookies.get('token')
        },
        method: 'POST',
        body: data
    };
    return fetch(process.env.VUE_APP_APIURL + `/upload/image`, requestOptions).then(handleResponse);
}

export const uploadImgService = {
    uploadImage
};
