import { partnersService } from '@/api/services/partners.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    registerPartners({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return partnersService.registerPartners(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updatePartners({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return partnersService.updatePartners(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerPartnersSupport({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return partnersService.registerPartnersSupport(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updatePartnersSupport({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return partnersService.updatePartnersSupport(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listPartners({ dispatch, commit }, query) {
        commit('listRequest', []);
        return partnersService.listPartners(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listPartnersSupport({ dispatch, commit }, id) {
        commit('listRequest', []);
        return partnersService.listPartnersSupport(id).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailPartners({ dispatch, commit }, data) {
        commit('listRequest', []);
        return partnersService.detailPartners(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailPartnersSupport({ dispatch, commit }, data) {
        commit('listRequest', []);
        return partnersService.detailPartnersSupport(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deletePartners({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return partnersService.deletePartners(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deletePartnersSupport({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return partnersService.deletePartnersSupport(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getPartnersSettingPhone({ dispatch, commit }, data) {
        commit('listRequest', []);
        return partnersService.getPartnersSettingPhone(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    CHECK_PARTNER_ORDER_SCOPE_CAMPAIGN({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return partnersService.CHECK_PARTNER_ORDER_SCOPE_CAMPAIGN(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
