import { authHeader, handleResponse } from '@/api/handler/main';

export const partnersService = {
    registerPartners,
    registerPartnersSupport,
    listPartners,
    listPartnersSupport,
    detailPartners,
    detailPartnersSupport,
    deletePartners,
    deletePartnersSupport,
    updatePartners,
    updatePartnersSupport,
    getPartnersSettingPhone,
    CHECK_PARTNER_ORDER_SCOPE_CAMPAIGN
};

function registerPartners(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners`, requestOptions).then(handleResponse);
}
function registerPartnersSupport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges`, requestOptions).then(handleResponse);
}

function detailPartners(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + data, requestOptions).then(handleResponse);
}
function detailPartnersSupport(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function listPartners(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners?` + query, requestOptions).then(handleResponse);
}

function listPartnersSupport(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + id + `/person-in-charges`, requestOptions).then(handleResponse);
}

function CHECK_PARTNER_ORDER_SCOPE_CAMPAIGN(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/filter-in-campaign/list?${query}`, requestOptions).then(handleResponse);
}

function deletePartners(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + data.id, requestOptions).then(handleResponse);
}

function deletePartnersSupport(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function updatePartners(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + data.id, requestOptions).then(handleResponse);
}

function updatePartnersSupport(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function getPartnersSettingPhone() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/phone-setting/get-partners`, requestOptions).then(handleResponse);
}
