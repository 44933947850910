export const routerClient = [
    {
        path: '/client/list',
        name: 'Client',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['client.view'],
            typeRouterForm: false,
            titleJP: 'エンドクライアント一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/client')
    },
    {
        path: '/client/form',
        name: 'ClientForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['client.register', 'client.edit'],
            typeRouterForm: true,
            titleJPAdd: 'エンドクライアント新規登録',
            titleJPEdit: 'エンドクライアント編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント新規登録',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント一覧',
                    href: '/client/list'
                },
                {
                    text: 'エンドクライアント編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/client/form/main.vue')
    },
    {
        path: '/client/form/view',
        name: 'ClientView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['client.view'],
            typeRouterForm: false,
            titleJP: 'エンドクライアント詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント一覧',
                    href: '/client/list'
                },
                {
                    text: 'エンドクライアント詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/client/view/main.vue')
    },
    {
        path: '/client/form/support',
        name: 'ClientFormView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['client.register', 'client.edit'],
            typeRouterForm: true,
            titleJPAdd: 'エンドクライアント担当者登録',
            titleJPEdit: 'エンドクライアント担当者編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント一覧',
                    href: '/client/list'
                },
                {
                    text: 'エンドクライアント詳細',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント一覧',
                    href: '/client/list'
                },
                {
                    text: 'エンドクライアント詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/client/form/support.vue')
    },
    {
        path: '/client/form/support/view',
        name: 'ClientFormSupportView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['client.view'],
            typeRouterForm: false,
            titleJP: 'エンドクライアント担当者詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント一覧',
                    href: '/client/list'
                },
                {
                    text: 'エンドクライアント詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/client/view/support.vue')
    }
];
