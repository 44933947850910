export const routerRequire = [
    {
        path: '/require',
        name: 'Require',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'パートナー依頼シート',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'パートナー依頼シート',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/require/index.vue')
    }
];
