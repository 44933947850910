export const routerCampaign = [
    {
        path: '/campaign/list',
        name: 'Campaign',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'キャンペーン一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/campaign')
    },
    {
        path: '/campaign/form',
        name: 'CampaignForm',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: true,
            titleJPAdd: 'キャンペーン新規登録',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン新規登録',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/campaign/form/main.vue')
    },
    {
        path: '/campaign/edit',
        name: 'CampaignEdit',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: true,
            titleJPEdit: 'キャンペーン 編集',
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    href: '/campaign/list'
                },
                {
                    text: 'キャンペーン 編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/campaign/edit/main.vue')
    },
    {
        path: '/campaign/form/view',
        name: 'CampaignView',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'キャンペーン詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    href: '/campaign/list'
                },
                {
                    text: 'キャンペーン詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/campaign/view/main.vue')
    }
];
