export const routerManagerForm = [
    {
        path: '/manager-form/list',
        name: 'ManagerFormList',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'フォーム一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'フォーム一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/manager-form')
    },
    {
        path: '/manager-form/create',
        name: 'ManagerFormCreate',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: true,
            titleJPAdd: '新規作成',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '新規作成',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/manager-form/create/main.vue')
    },
    {
        path: '/manager-form/template-create',
        name: 'ManagerFormCreateTemplate',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: true,
            titleJPAdd: 'テンプレートから作成',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'テンプレートから作成',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/manager-form/template/main.vue')
    },
    {
        path: '/manager-form/edit',
        name: 'ManagerFormEdit',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: true,
            titleJPEdit: 'フォーム編集',
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    href: '/manager-form/list'
                },
                {
                    text: 'フォーム編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/manager-form/edit/main.vue')
    },
    {
        path: '/manager-form/view',
        name: 'ManagerFormView',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'フォーム詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    href: '/manager-form/list'
                },
                {
                    text: 'フォーム詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/phase2/manager-form/view/main.vue')
    },

    {
        path: '/manager-form/iframe-page',
        name: 'ManagerFormIframePage',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: [],
            typeRouterForm: false,
            titleJP: 'ViewIframe'
        },
        component: () => import('@/views/pages/phase2/manager-form/iframe-page/main.vue')
    }
];
